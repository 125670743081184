import styles from './styles.module.css';
import React from 'react';
import { Helmet } from 'react-helmet';

import { SectionContainer } from 'src/components/sectionContainer';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import { ContentfulVideoResource, Maybe } from 'src/graphql-types';
import { Video } from 'src/components/video';
import {
	SERVER_RESPONSE_NO_ACCESS,
	SERVER_RESPONSE_NO_ACCESS_TO_USER_TYPE,
	formatDate,
} from 'src/utils/common';
import { ApolloError } from 'apollo-client';
import { LoginForMore } from 'src/components/loginComponents/loginForMore';
import { curatedTitle } from 'src/utils/curated-title';
import { defaultRichTextOptions, getRichText } from '../utils/richTextOptions/defaultOptions';

interface PageContext {
	pageContext: {
		video: Maybe<ContentfulVideoResource>;
		loading?: boolean;
		error?: ApolloError;
		refetch?: (params?: any) => any;
	};
}

interface MessageProps {
	message: string;
}

const SimpleMessageContent = (props: MessageProps) => {
	return (
		<SectionContainer withBorderTop>
			<p className={styles.message}>{props.message}</p>
		</SectionContainer>
	);
};

export default class VideoDetailsTemplate extends React.Component<PageContext> {
	render() {
		const { video, loading = false, error, refetch } = this.props.pageContext;

		const notLoggedIn = error && error.message === SERVER_RESPONSE_NO_ACCESS;
		const notAuthorised = error && error.message === SERVER_RESPONSE_NO_ACCESS_TO_USER_TYPE;

		const getContent = () => {
			if (loading) {
				return <SimpleMessageContent message="Loading content, please wait" />;
			}
			if (error) {
				if (notLoggedIn) {
					return <LoginForMore onRefetch={refetch} withSectionContainer />;
				} else if (notAuthorised) {
					return <SimpleMessageContent message="this content is not available" />;
				} else {
					return (
						<SimpleMessageContent message="There was a problem getting the resource, please try again later." />
					);
				}
			}
			if (video) {
				const {
					contentful_id,
					summary,
					title,
					publicationDate,
					externalVideoUrl,
					videoFile,
					thumbnailLink,
					otherLanguageAssets,
				} = video;

				return (
					<SectionContainer isGrey withBorderTop>
						<div className={styles.titleContainer}>
							<h4 className={styles.titleText}>{title}</h4>
							{publicationDate && (
								<h5 className={styles.datePublished}>{formatDate(publicationDate)}</h5>
							)}
						</div>
						<div className={styles.videoSection}>
							<div className={styles.detailsContainer}>
								{summary && <div>{getRichText(summary, defaultRichTextOptions)}</div>}
								<Video
									videoUrl={externalVideoUrl || videoFile?.file?.url}
									thumbnailImageSrc={thumbnailLink?.thumbnailImage?.fluid?.src}
									withoutThumbnail
								/>
							</div>
							<div className={styles.metadataContainer}>
								{otherLanguageAssets && otherLanguageAssets.length > 0 && (
									<>
										<div className={styles.metadataTitle}>Other Languages</div>
										{otherLanguageAssets
											.filter((ol: any) => ol)
											.map((ol: any, i: number) => {
												const href = ol.theAsset?.file?.url || ol.externalAssetUrl;
												const download = ol.theAsset?.file?.url !== undefined;
												const target = download ? undefined : '_blank';
												if (href) {
													return (
														<p
															key={`${ol.contentful_id}_${i}`}
															className={styles.languageContainer}
														>
															<a
																className={styles.otherLanguagesLink}
																key={ol.contentful_id || ''}
																href={href}
																download={download}
																target={target}
															>
																{ol.language}
															</a>
														</p>
													);
												}
												return undefined;
											})}
									</>
								)}
							</div>
						</div>
					</SectionContainer>
				);
			}

			return (
				<SimpleMessageContent message="There was a problem getting the resource, please try again later." />
			);
		};

		return (
			<DefaultLayoutTemplate showLogin={notLoggedIn} onLoginSuccess={refetch}>
				<Helmet>
					<title>{curatedTitle(this.props.pageContext.video?.title)}</title>
				</Helmet>
				{getContent()}
			</DefaultLayoutTemplate>
		);
	}
}
